<template>
  <div class="admin-page mx-auto row no-feature" style="min-height: 100vh;">
    <div class="navbar-pad"></div>
    <div class="sidebar-toggle d-md-none py-3 sticky-top bg-light">
      <button
        class="btn btn-primary w-100 py-3 text-nowrap"
        @click="expandSidebar = true"
      >
        <i class="far fa-caret-square-right me-3" />
        <span class="text-warning">Admin Section</span>
        <i class="far fa-caret-right text-light mx-3" />
        <span class="d-none d-sm-inline-block">
          {{ ($route.meta && $route.meta.title) || $route.name }}
        </span>
        <span class="d-sm-none">
          {{
            $route.meta && $route.meta.title
              ? $route.meta.title.replace("Manage ", "")
              : $route.name
          }}
        </span>
      </button>
    </div>
    <div
      class="
        px-0 px-md-3
        d-flex
        justify-content-between
        align-items-start
        position-relative
        bg-light
      "
    >
      <template v-if="!isLoading">
        <sidebar-left
          class="sidebar-admin user-select-none text-light"
          :class="{ expand: expandSidebar }"
        >
          <h6
            class="
              mb-0
              p-3
              fw-medium
              bg-primary
              rounded
              d-flex
              justify-content-between justify-content-md-center
              align-items-center
            "
          >
            ADMIN SECTION
            <button
              class="btn btn-link text-light text-decoration-none p-0 d-md-none"
              @click="expandSidebar = false"
            >
              <i class="fas fa-times" />
            </button>
          </h6>
          <ul class="sidebar-links py-3">
            <li
              v-for="link in sidebarLinks"
              :key="link.name"
              class="text-nowrap"
              :class="{ 'is-active': $route.name == link.name }"
            >
              <router-link
                class="text-decoration-none stretched-link"
                :to="{ name: link.name }"
                @click.native="expandSidebar = false"
              >
                <i class="fas fa-circle text-primary me-2" />
                {{ (link.meta && link.meta.title) || link.name }}
              </router-link>
            </li>
          </ul>
        </sidebar-left>
        <div
          class="
            px-3
            pt-0 pt-md-4
            pb-4
            flex-fill
            container-lg
            mx-auto
            position-relative
          "
          style="overflow-x: hidden"
        >
          <router-view />
        </div>
      </template>
      <loading-spinner class="position-relative bg-light vh-100" v-else />
    </div>
  </div>
</template>

<script>
import routes from "@/router/routes";
import SidebarLeft from "@/components/SidebarLeft";
import LoadingSpinner from "@/components/LoadingSpinner";

export default {
  data() {
    return {
      isLoading: false,
      expandSidebar: false,
      sidebarLinks: routes.find((route) => route.name == "Admin").children,
    };
  },
  components: {
    SidebarLeft,
    LoadingSpinner,
  },
  watch: {
    $route: {
      handler() {
        this.checkUserRole();
      },
      immediate: true,
      deep: true,
    },
    expandSidebar: {
      handler() {
        if (this.expandSidebar) {
          document.body.classList.add("overflow-hidden", "overflow-hidden-md");
        } else {
          document.body.classList.remove(
            "overflow-hidden",
            "overflow-hidden-md"
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    checkUserRole() {
      this.isLoading = true;

      this.API.get("users/me")
        .then((retVal) => {
          const user = retVal.data;

          if (!user || !user.role || user.role.name != "Admin") {
            this.$router.replace({ name: "SignIn" });
          } else if (this.$route.name == "Admin") {
            this.$router.replace(this.sidebarLinks[0]);
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>